type ConfigConstructor = {
  authority: string;
  baseUrl: string;
  clientId: string;
  gitSha: string;
  version: string;
};

export class Config {
  _authority: string;

  _basePath: string;

  _baseUrl: string;

  _clientId: string;

  _gitSha: string;

  _version: string;

  constructor({ authority, baseUrl, clientId, gitSha, version }: ConfigConstructor) {
    this._authority = authority;
    this._baseUrl = baseUrl;
    this._clientId = clientId;
    this._gitSha = gitSha;
    this._version = version;

    this._basePath = new URL(this.baseUrl).pathname;
  }

  get authority(): string {
    return this._authority;
  }

  get basePath(): string {
    return this._basePath;
  }

  get baseUrl(): string {
    return this._baseUrl;
  }

  get clientId(): string {
    return this._clientId;
  }

  get gitSha(): string {
    return this._gitSha;
  }

  get version(): string {
    return this._version;
  }
}
