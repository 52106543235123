import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { muiTheme } from 'nm-ui-components';

import PackageInfo from 'pages/packageInfo';

function App() {
  return (
    <>
      <h1>Hello Full Stack UI</h1>
      <ThemeProvider theme={muiTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/.nm/info" element={<PackageInfo />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
